<template>
    <Card title="管道完整性评价">

        <a-tabs v-model="activeKey" @change="onChange">
      <a-tab-pane key="1" tab="管道检测任务">
        <Task @toggle="toggle" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="管道检测结果">
            <Result :taskName="taskName" />
      </a-tab-pane>
    </a-tabs>


    </Card>
  </template>
  
  <script>
  import Result from './components/Result.vue';
import Task from './components/Task.vue';
  export default {
    components: {
        Task,
        Result,
    },
    data() {
        return {
            activeKey: '1',
            taskName: '',
        }
    },

    mounted() {
      this.activeKey =  window.localStorage.getItem('pipe-check-active-key') || '1'
    },

    methods: {
        onChange(key) {
            this.activeKey = key;
            window.localStorage.setItem('pipe-check-active-key', key)
        },

        toggle(taskName) {
          this.onChange('2')
          this.taskName = taskName;
        }
    }
  };
  </script>