<template>

  <div>

    <a-row style="margin-bottom: 2vh">
      <a-col :span="22">
        <a-form-model layout="inline" @keyup.enter.native="query">
          <a-form-model-item>
            <a-input v-model="form.name" placeholder="所属管道检测任务" />
          </a-form-model-item>

          <a-form-model-item>
            <a-input v-model="form.pipeCode" placeholder="管道编号" />
          </a-form-model-item>

          <a-form-model-item>
            <a-select v-model="form.type" placeholder="检测结果" style="width: 180px">
              <a-select-option v-for="item in [{
                value: '1',
                name: '正常',
              }, {
                value: '0',
                name: '异常'
              }]" :key="item.value" :value="item.value">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-input v-model="form.content" placeholder="检测详情" />
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-col>

      <a-col :span="2">
        <div class="right" style="margin-top: 4px">
          <a-button @click="$router.push('/manage/pipe-check/result-editor')" type="primary">新建检测结果</a-button>
        </div>
      </a-col>
    </a-row>

    <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
      total,
      current,
      pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    }" rowKey="id">
      <!--<a-table-column key="number" title="所属管道检测任务" data-index="number"></a-table-column>-->
      <a-table-column key="name" title="所属检测任务" align="center" data-index="name"></a-table-column>
      <a-table-column key="pipeCode" title="管道编号" data-index="pipeCode" align="center"></a-table-column>
      <!--
      <a-table-column key="pipeType" title="管道类型" align="center">
        <template slot-scope="text">
          <span>
            {{ text.pipeType === 'sewage' ? '污水' : '雨水' }}
          </span>
        </template>
</a-table-column>
-->
      <a-table-column key="type" title="检测结果" align="center">
        <template slot-scope="text">
          <span>
            <a-badge v-if="String(text.result) === '1'" color="success" text="正常" />
            <a-badge v-if="String(text.result) === '0'" color="error" text="异常" />
          </span>
        </template>
      </a-table-column>

      <a-table-column key="content" title="检测详情" data-index="content"></a-table-column>
      <a-table-column key="time" title="检测时间" data-index="time" align="center"></a-table-column>
      <a-table-column key="videoVo" title="关联文件" align="center">
        <template slot-scope="text">
          <span>
            <a v-if="text.videoVo" :href="'http://' + text.videoVo.completePath" target="_blank">{{ text.videoVo ?
              text.videoVo.name : '' }}</a>
          </span>
        </template>
      </a-table-column>



      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text">
          <!-- v-show="permissionEdit" -->
          <a-space>
            <a href="#" @click.prevent="$router.push('/manage/pipe-check/result-editor?id=' + text.id)">编辑</a>
            <a href="#" @click.prevent="deleteText(text)" class="danger">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

  </div>

</template>

<script>
import { fetchResultList, removeResult } from '../api'

export default {

  props: {
    taskName: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  watch: {
    taskName() {
      this.form.name = this.taskName;
      this.getList();
    }
  },

  mounted() {
    this.form.name = this.taskName;
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchResultList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确定要删除吗？",
        onOk() {
          removeResult(text).then(() => {
            that.getList();
          });
        },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

  },
};
</script>