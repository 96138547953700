var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{staticStyle:{"margin-bottom":"2vh"}},[_c('a-col',{attrs:{"span":22}},[_c('a-form-model',{attrs:{"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"所属管道检测任务"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"管道编号"},model:{value:(_vm.form.pipeCode),callback:function ($$v) {_vm.$set(_vm.form, "pipeCode", $$v)},expression:"form.pipeCode"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"检测结果"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l(([{
                value: '1',
                name: '正常',
              }, {
                value: '0',
                name: '异常'
              }]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"检测详情"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"right",staticStyle:{"margin-top":"4px"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push('/manage/pipe-check/result-editor')}}},[_vm._v("新建检测结果")])],1)])],1),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
      total: _vm.total,
      current: _vm.current,
      pageSize: _vm.pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    },"rowKey":"id"},on:{"change":_vm.onChange}},[_c('a-table-column',{key:"name",attrs:{"title":"所属检测任务","align":"center","data-index":"name"}}),_c('a-table-column',{key:"pipeCode",attrs:{"title":"管道编号","data-index":"pipeCode","align":"center"}}),_c('a-table-column',{key:"type",attrs:{"title":"检测结果","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',[(String(text.result) === '1')?_c('a-badge',{attrs:{"color":"success","text":"正常"}}):_vm._e(),(String(text.result) === '0')?_c('a-badge',{attrs:{"color":"error","text":"异常"}}):_vm._e()],1)]}}])}),_c('a-table-column',{key:"content",attrs:{"title":"检测详情","data-index":"content"}}),_c('a-table-column',{key:"time",attrs:{"title":"检测时间","data-index":"time","align":"center"}}),_c('a-table-column',{key:"videoVo",attrs:{"title":"关联文件","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',[(text.videoVo)?_c('a',{attrs:{"href":'http://' + text.videoVo.completePath,"target":"_blank"}},[_vm._v(_vm._s(text.videoVo ? text.videoVo.name : ''))]):_vm._e()])]}}])}),_c('a-table-column',{key:"control",attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/manage/pipe-check/result-editor?id=' + text.id)}}},[_vm._v("编辑")]),_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteText(text)}}},[_vm._v("删除")])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }