<template>

  <div>

    <a-row style="margin-bottom: 2vh">
      <a-col :span="22">
        <a-form-model layout="inline" @keyup.enter.native="query">
          <a-form-model-item>
            <a-input v-model="form.name" placeholder="任务名" />
          </a-form-model-item>

          <a-form-model-item>
            <a-date-picker v-model="form.time" style="width: 180px" placeholder="任务时间" />
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-col>

      <a-col :span="2">
        <div class="right" style="margin-top: 4px">
          <a-button @click="$router.push('/manage/pipe-check/task-editor')" type="primary">新建任务</a-button>
        </div>
      </a-col>
    </a-row>

    <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
      total,
      current,
      pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    }" rowKey="id">
      <a-table-column key="name" title="任务名" data-index="name"></a-table-column>
      <a-table-column key="time" title="任务时间" data-index="time" align="center"></a-table-column>
      <a-table-column key="fileVos" title="相关文件" align="center">
        <template #default="{ fileVos }">
          <div>
            <div v-for="file in fileVos" :key="file.id">
              <a :href="file.completePath" target="_blank">{{ file.name }} </a>
            </div>

          </div>
        </template>
      </a-table-column>

      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text">
          <!-- v-show="permissionEdit" -->
          <a-space>
            <a href="#" @click.prevent="$emit('toggle', text.name)">检测结果</a>
            <a href="#" @click.prevent="$router.push('/manage/pipe-check/task-editor?id=' + text.id)">编辑</a>
            <a href="#" @click.prevent="deleteText(text)" class="danger">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

  </div>

</template>

<script>
import { fetchList, remove } from '../api'

export default {
  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
        time: this.form.time ? this.form.time.format("YYYY-MM-DD") : undefined
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确定要删除吗？",
        onOk() {
          remove(text).then(() => {
            that.getList();
          });
        },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>