import request from '@/api/request'

export function fetchList(data) {
    return request({
        url: '/model-analysis/pipe/check/task/list',
        method: 'post',
        data,
    })
}

export function add(data) {
    return request({
        url: '/model-analysis/pipe/check/task/save',
        method: 'post',
        data,
    })
}

export function remove({id}) {
    return request({
        url: '/model-analysis/pipe/check/task/delete/' + id,
        method: 'post',
    })
}

export function fetchDetail({id}) {
    return request({
        url: '/model-analysis/pipe/check/task/query/' + id,
    })
}

export function edit(data) {
    return request({
        url: '/model-analysis/pipe/check/task/modify',
        method: 'post',
        data,
    })
}


export function fetchResultList(data) {
    return request({
        url: '/model-analysis/pipe/check/result/list',
        method: 'post',
        data,
    })
}

export function addResult(data) {
    return request({
        url: '/model-analysis/pipe/check/result/save',
        method: 'post',
        data,
    })
}

export function removeResult({id}) {
    return request({
        url: '/model-analysis/pipe/check/result/delete/' + id,
        method: 'post',
    })
}

export function fetchResultDetail({id}) {
    return request({
        url: '/model-analysis/pipe/check/result/query/' + id,
    })
}

export function editResult(data) {
    return request({
        url: '/model-analysis/pipe/check/result/modify',
        method: 'post',
        data,
    })
}